import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent, ContentType } from '../components/Content';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Iframe from 'react-iframe';
import { getCookieConsentState } from '../components/Footer';

const encode = (data: any) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const CustomAlert = ({ show, variant, messages }: CustomAlertType) => {
  return (
    <Alert variant={variant} show={show}>
      {messages.map((message, idx) => (
        <p key={`contact-error-${idx}`}>{message}</p>
      ))}
    </Alert>
  );
};

type CustomAlertType = {
  show: boolean,
  variant:
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'dark'
  | 'light',
  messages: Array<string>,
};

type StateType = {
  loading: boolean,
  formData: any,
  hasError: boolean,
  errors: Array<string>,
  hasSuccess: boolean,
  successMessages: Array<string>,
};

export class ContactPageTemplate extends React.Component<ContactPageTemplateType> {
  state: StateType;

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      formData: {
        salutation: 'Frau'
      },
      hasError: false,
      errors: [],
      hasSuccess: false,
      successMessages: [],
    };
  }

  handleChange = (e: any) => {
    const input = e.target;
    const key = input.id;
    const value = input.type === 'checkbox' ? input.checked : input.value;
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: value,
      }
    });
  };

  showError = (messages: string[]) => {
    this.setState({
      loading: false,
      hasError: true,
      errors: messages,
    });
  };

  handleSubmit = (e: any) => {
    e.preventDefault();

    this.setState({
      loading: true,
      hasError: false,
      errors: [],
      hasSuccess: false,
      successMessages: [],
    });

    const me = this;

    if (typeof grecaptcha === `undefined`) {
      me.showError(['Google reCAPTCHA konnte nicht geladen werden. Prüfen Sie Ihre Cookie-Einstellungen.']);
      return;
    }

    grecaptcha.ready(() => {
      const token = grecaptcha.getResponse();
      if (!token) {
        me.showError(['Google reCAPTCHA muss ausgefüllt sein.']);
        return;
      }

      const key = 'gRecaptchaResponse';
      me.setState({
        formData: {
          ...me.state.formData,
          [key]: token,
        }
      });

      fetch('https://europe-west1-lebensessenz-gd.cloudfunctions.net/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          ...me.state.formData,
        }),
      }).then((data) => {
        return data.json();
      }).then((data) => {
        console.log(data);
        if (data.status === 'ok') {
          me.setState({
            loading: false,
            hasSuccess: true,
            successMessages: [`Vielen Dank für Ihre Nachricht, ${data.data.customer}. Wir werden uns schnellstmöglichst um Ihr Anliegen kümmern. Sie haben eine Kopie Ihrer Anfrage an Ihre E-Mail Adresse ${data.data.email} erhalten.`],
          });
          return;
        }

        if (data.status === 'error') {
          me.showError(data.errors);
          return;
        }

        me.showError(['Unbekannter Fehler, bitte versuchen Sie es erneut.']);
        return;
      }).catch(() => {
        me.showError(['Unbekannter Fehler, bitte versuchen Sie es erneut.']);
        return;
      });
    });
  };

  render() {
    const PageContent = this.props.contentComponent || Content;

    const cookieConsentData = getCookieConsentState();

    return (
      <>
        <Helmet>
          {cookieConsentData && cookieConsentData.cookieStatistics ? (
            <script src="https://www.google.com/recaptcha/api.js" async defer></script>
          ) : null}
        </Helmet>
        <Container>
          <h1>
            {this.props.title}
          </h1>
          <PageContent className="content" content={this.props.address} />
          <Link to="/kontakt#kontakt-form" className="btn btn-primary">Jetzt Kontakt aufnehmen</Link>
        </Container>
        <Container fluid className="container-full-width my-5">
          {cookieConsentData && cookieConsentData.cookieStatistics ?
            <Iframe
              url={this.props.embeddedGoogleMapsLink}
              className="iframe-full-width iframe-no-border"
            />
            :
            <div className="iframe-full-width text-center text-white bg-dark pt-5">
              Google Maps kann erst nach Bestätigung der Cookie-Richtlinie geladen werden.
            </div>
          }
        </Container>
        <div id="kontakt-form" className="rel-anchor"></div>
        <Container>
          <p>{this.props.description}</p>

          <Form onSubmit={this.handleSubmit}>
            <Form.Row>
              <Col sm={2}>
                <Form.Group controlId="salutation">
                  <Form.Label>Anrede*</Form.Label>
                  <Form.Control as="select" onChange={this.handleChange}>
                    <option>Frau</option>
                    <option>Herr</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm={5}>
                <Form.Group controlId="firstName">
                  <Form.Label>Vorname*</Form.Label>
                  <Form.Control required type="text" placeholder="Vorname" onChange={this.handleChange} />
                </Form.Group>
              </Col>
              <Col sm={5}>
                <Form.Group controlId="lastName">
                  <Form.Label>Nachname*</Form.Label>
                  <Form.Control required type="text" placeholder="Nachname" onChange={this.handleChange} />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm={6}>
                <Form.Group controlId="email">
                  <Form.Label>E-Mail Adresse*</Form.Label>
                  <Form.Control required type="email" placeholder="meine@email.de" onChange={this.handleChange} />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="phone">
                  <Form.Label>Telefon-Nummer</Form.Label>
                  <Form.Control type="text" placeholder="+49 1234 56789012" onChange={this.handleChange} />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Group controlId="message">
              <Form.Label>Nachricht*</Form.Label>
              <Form.Control as="textarea" rows="5" placeholder="Ihre Nachricht" onChange={this.handleChange} />
            </Form.Group>
            <Form.Group controlId="confirmation">
              <Form.Check type="checkbox" custom required>
                <Form.Check.Input type="checkbox" onChange={this.handleChange} />
                <Form.Check.Label>Ich bin der Verarbeitung meiner angegebenen Daten zur Bearbeitung meiner Anfrage einverstanden. Weitere Informationen in der <Link to="/datenschutz">Datenschutzerklärung</Link>.*</Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <p>Mit * gekennzeichnete Felder sind Pflichtfelder.</p>

            <div
              className="g-recaptcha"
              data-sitekey="6Lfu-egUAAAAADiyyEWfqUK-oengZsYuXAF-xMkV"
            ></div>

            <CustomAlert show={this.state.hasError} variant="danger" messages={this.state.errors} />
            <CustomAlert show={this.state.hasSuccess} variant="success" messages={this.state.successMessages} />
            <CustomAlert show={!cookieConsentData?.cookieStatistics} variant="danger" messages={["Google reCAPTCHA konnte nicht geladen werden. Prüfen Sie Ihre Cookie-Einstellungen."]} />

            <Button type="submit" disabled={this.state.loading}>Absenden <i hidden={!this.state.loading} className="fas fa-spinner fa-pulse"></i></Button>
          </Form>
        </Container>
      </>
    );
  };
};

type ContactPageTemplateType = {
  contentComponent?: { (data: ContentType): any },
  title: string,
  embeddedGoogleMapsLink: string,
  description: string,
  address: string,
};

class ContactPage extends React.Component<ContactPageType> {
  render() {
    return (
      <Layout>
        <ContactPageTemplate
          contentComponent={HTMLContent}
          title={this.props.data.markdownRemark.frontmatter.title}
          embeddedGoogleMapsLink={this.props.data.markdownRemark.frontmatter.embeddedGoogleMapsLink}
          description={this.props.data.markdownRemark.frontmatter.description}
          address={this.props.data.markdownRemark.html}
        />
      </Layout>
    );
  };
};

type ContactPageType = {
  data: {
    markdownRemark: {
      html: string,
      frontmatter: {
        title: string,
        embeddedGoogleMapsLink: string,
        description: string,
      },
    },
  },
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title,
        embeddedGoogleMapsLink,
        description,
      }
    }
  }
`;
